
























import Vue from 'vue';
import * as monaco from 'monaco-editor';
import MonacoEditor from 'vue-monaco';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { EditorOptions, Field } from '@/models/form';
import debounce from 'lodash.debounce';

@Component({
  components: {
    MonacoEditor,
  },
})
export default class HdQueryEditor extends Vue {
  @Prop({ required: true }) field!: Field;

  @Prop({ required: true }) editorOptions!: EditorOptions;

  @Prop({ default: false }) disabled!: boolean;

  monacoOptions: any;

  height = 51;
  width = 1500;

  debounceResizeEditor: () => void;

  constructor() {
    super();
    this.debounceResizeEditor = debounce(() => {
      const editor = this.$refs.editor as any;
      const monacoEditor = editor.getEditor();
      const contentHeight = monacoEditor.getContentHeight();
      const lineHeight = monacoEditor.getOption(
        monaco.editor.EditorOption.lineHeight
      );
      const newHeight = Math.min(600, contentHeight + lineHeight);

      const parentWidth = editor.$el.parentElement.clientWidth;

      if (this.height === newHeight && this.width === parentWidth) return;

      this.height = newHeight;
      this.width = parentWidth;

      editor.$el.style.height = `${this.height}px`;
      editor.$el.style.width = `${this.width}px`;

      this.$nextTick(() => {
        monacoEditor.layout();
      });
    }, 100);
  }

  @Watch('editorOptions', { deep: true, immediate: true })
  onOptionsUpdated() {
    this.monacoOptions = {
      contextmenu: false,
      wordWrap: 'on',
      scrollbar: {
        horizontal: 'hidden',
        verticalHasArrows: true,
      },
      scrollBeyondLastLine: false,
      smoothScrolling: true,
      renderLineHighlight: 'none',
      renderControlCharacters: true,
      lineNumbers: false,
      readOnly: this.editorOptions.readOnly,
      minimap: {
        enabled: false,
      },
      theme: 'hdql-dark',
    };
  }

  mounted() {
    this.debounceResizeEditor();
  }

  checkZeroWidthSpace(): boolean {
    const spanList = document.querySelectorAll('span');
    let charPosition = 0;
    let bFound = false;

    for (let i = 0; i < spanList.length; i += 1) {
      if (spanList[i].className.includes('mtk')) {
        for (charPosition = 0; charPosition < spanList[i].innerHTML.length; charPosition += 1) {
          if (spanList[i].innerHTML.charCodeAt(charPosition) === 8203) {
            spanList[i].style.color = 'red';
            bFound = true;
          }
        }
      }
    }

    return bFound;
  }

  @Watch('value')
  onValueChanged() {
    this.debounceResizeEditor();
    this.field.errors = [];
  }

  get value() {
    return this.field.value ?? '';
  }

  set value(val: string) {
    this.field.value = val;
  }
}
